/* You can add global styles to this file, and also import other style files */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "definitions";

body {
    overflow-x: auto;
    min-height: 0;
}

.body__content {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
}

.app-icon {
    background: transparent url("./assets/svg-icons/app-icon.svg") no-repeat center center;
    background-size: 36px;
    margin: 8px auto;
}

@keyframes ghost-ui {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Added and overrided styles for Logex FW */
.table__row--header {
    color: $gray-color-80;
}

.lg-dropdown--borderless {
    border: none;

    &:focus {
        box-shadow: none;
        border: none;
    }
}
